.center-flex {
  display: flex;
  justify-content: center;
  align-items: center;
}

.column-flex {
  display: flex;
  flex-direction: column;
}

.helper-text {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Helvetica, Arial, sans-serif, 'Apple Color Emoji',
    'Segoe UI Emoji';
  font-weight: 400;
  font-size: 0.75rem;
  line-height: 1.66;
  text-align: left;
  margin-top: 3px;
  margin-right: 14px;
  margin-bottom: 0;
}

.error-text {
  color: red;
  @extend .helper-text;
}

.disable-focus-phone-input>input:focus {
  outline: none;
}

.small-hidden {
  @media screen and (max-width: 1919px) {
    visibility: hidden;
    display: none;
  }
}